import React from "react";
import { motion } from "framer-motion";
import bgImage from "../../assets/images/BLOB.png";

const HeroSection = () => {
  // Varianti per le animazioni
  const textVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.6 } },
  };

  return (
    <section className="bg-white py-10 h-[600px] relative">
      <div className="container flex flex-col lg:flex-row items-center justify-between pl-5 lg:ml-20 mt-20">
        {/* Left Side - Text */}
        <motion.div
          className="lg:w-1/2 text-left space-y-4"
          initial="hidden"
          animate="visible"
          variants={textVariants}
        >
          <motion.p className=" text-sm w-3/4 md:w-full md:text-base lg:text-lg text-[#05b4fc]">
            Porte e Serramenti di Qualità su Misura per la Tua Casa
          </motion.p>
          <motion.h1 className="text-4xl w-3/4 lg:text-6xl font-extrabold">
            LA TUA SOLUZIONE PER SERRAMENTI DI ECCELLENZA
          </motion.h1>
        </motion.div>
      </div>

      {/* Right Side - Images */}
      <motion.div
        className="hidden md:block absolute lg:w-[350px] md:w-[200px] lg:h-[350px] md:h-[200px] md:top-40 lg:top-24 md:right-10 lg:right-64 z-10 rounded-2xl"
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0, transition: { duration: 1 } }}
      >
        <img
          src="https://lh3.googleusercontent.com/p/AF1QipPjCRE1022IZziRVRPxiAWHMeemnrlBfuZR54DA=s680-w680-h510"
          alt=""
          className="w-full h-full"
        />
      </motion.div>
      <motion.div
        className="hidden lg:block absolute top-[30%] lg:right-10 lg:w-[200px] lg:h-[300px] z-10 rounded-2xl"
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0, transition: { duration: 1, delay: 0.2 } }}
      >
        <img
          src="https://lh3.googleusercontent.com/p/AF1QipMB3S0J8Qm93F7iDFxJPT9j6l1A-ANR0BbBrFdZ=s680-w680-h510"
          alt=""
          className="w-full h-full"
        />
      </motion.div>

      {/* Blue Shape (Animated with scroll) */}
      <motion.div
        className="hidden md:block absolute md:top-[35%] lg:top-[20%] md:-right-28 lg:right-0 z-0"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1, transition: { duration: 0.8 } }}
      >
        <img
          src={bgImage}
          alt="Blue Shape"
          className="md:w-[80%] lg:w-full h-full object-cover"
        />
      </motion.div>
    </section>
  );
};

export default HeroSection;
