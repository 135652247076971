import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cart";
import hamburgerReducer from "./hamburger";

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    hamburger: hamburgerReducer,
  },
});
