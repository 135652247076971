// Footer.js
import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ onShowCookieBanner }) => {
  return (
    <div className="bg-[#1b558f] text-white py-4 w-fulls ">
      <div className="container mx-auto text-center flex justify-center items-center flex-col">
        <p className="mb-2">
          © {new Date().getFullYear()} Bevilacqua Serramenti
        </p>
        {/* <div className="flex justify-center space-x-4">
          <a href="/privacy" className="hover:underline">
            Informativa sulla Privacy
          </a>
          <a href="/terms" className="hover:underline">
            Termini e Condizioni
          </a>
          <a href="/contact" className="hover:underline">
            Contattaci
          </a> */}
        <div className=" flex gap-2">
          <Link to="/cookiePolicy">
            <p className="hover:underline cursor-pointer">Cookie Policy</p>
          </Link>

          <button
            onClick={onShowCookieBanner}
            className="hover:underline cursor-pointer"
          >
            Cookie
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
