// import React from "react";
// import { Link } from "react-router-dom";
// import { FaShoppingCart } from "react-icons/fa";
// import { useDispatch } from "react-redux";
// import { addToCart } from "../store/cart";

// const ProductCart = (props) => {
//   const { id, name, image, slug } = props.data;
//   const dispatch = useDispatch();

//   const handleAddToCart = () => {
//     dispatch(
//       addToCart({
//         productId: id,
//         quantity: 1,
//       })
//     );
//   };

//   return (
//     <div className="bg-white p-5 rounded-xl shadow-sm flex flex-col justify-between h-full">
//       <Link to={slug} className="flex-grow">
//         <img
//           src={image}
//           alt={name}
//           className="w-full h-100 object-cover object-top"
//         />
//       </Link>
//       <div className="flex flex-col items-center mt-3">
//         <h3 className="text-2xl py-3 text-center font-medium">{name}</h3>
//         <div className="flex justify-center items-center mt-auto">
//           <button
//             onClick={handleAddToCart}
//             className="bg-gray-300 p-2 rounded-md text-sm hover:bg-gray-400 flex gap-2"
//           >
//             <FaShoppingCart size={25} />
//             Aggiungi al carrello
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductCart;
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaShoppingCart } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { addToCart } from "../store/cart";

const ProductCart = (props) => {
  const { id, name, image, slug } = props.data;
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false); // Stato per il messaggio di conferma

  const handleAddToCart = () => {
    dispatch(
      addToCart({
        productId: id,
        quantity: 1,
      })
    );
    setShowConfirmation(true); // Mostra il messaggio di conferma
    setTimeout(() => setShowConfirmation(false), 2000); // Nascondi il messaggio dopo 2 secondi
  };

  return (
    <div className="bg-white p-5 rounded-xl shadow-sm flex flex-col justify-between h-full relative">
      <Link to={slug} className="flex-grow">
        <img
          src={image}
          alt={name}
          className="w-full h-100 object-cover object-top"
        />
      </Link>
      <div className="flex flex-col items-center mt-3">
        <h3 className="text-2xl py-3 text-center font-medium">{name}</h3>
        <div className="flex justify-center items-center mt-auto">
          {/* Mostra il pulsante o il messaggio di conferma */}
          {showConfirmation ? (
            <div className="bg-green-500 text-white p-2 rounded-md text-sm text-center">
              <p>Aggiunto al carrello!</p>
            </div>
          ) : (
            <button
              onClick={handleAddToCart}
              className="bg-gray-300 p-2 rounded-md text-sm hover:bg-gray-400 flex gap-2"
            >
              <FaShoppingCart size={25} />
              Aggiungi al carrello
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCart;
