import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  statusTab: false,
};

const hamburgerSlice = createSlice({
  name: "hamburger",
  initialState,
  reducers: {
    toggleStatusTabHamburger(state) {
      if (state.statusTab === false) {
        state.statusTab = true;
      } else {
        state.statusTab = false;
      }
    },
  },
});
export const { toggleStatusTabHamburger } = hamburgerSlice.actions;
export default hamburgerSlice.reducer;
