import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { products } from "../products";
import ProductCart from "../components/productCart";
import bgHeader1 from "../assets/headers/headerbg1.webp";
import bgHeader2 from "../assets/headers/headerbg2.webp";
import bgHeader3 from "../assets/headers/headerbg3.webp";
import bgHeader4 from "../assets/headers/headerbg4.webp";
// import bgHeader5 from "../assets/headers/headerbg5.jpg";
import bgHeader6 from "../assets/headers/headerbg6.webp";

const Productspage = () => {
  const { products: category } = useParams(); // Ottieni il parametro dall'URL
  const [headerHeight, setHeaderHeight] = useState(window.innerHeight); // Altezza dinamica del div
  const [headerOpacity, setHeaderOpacity] = useState(1); // Opacità dinamica

  // Filtra i prodotti in base alla categoria
  const filteredProducts = products.filter(
    (product) => product.category === category
  );

  // Mappa le categorie alle immagini di background
  const backgroundImages = {
    "porte-interne": bgHeader1,
    "porte-blindate": bgHeader2,
    "serramenti-PVC": bgHeader3,
    "serramenti-legno-alluminio": bgHeader4,
    zanzariere: bgHeader6,
  };

  const formatCategory = (category) => {
    return category
      .replace(/-/g, " ") // Sostituisci i trattini con spazi
      .split(" ") // Dividi le parole
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalizza ogni parola
      .join(" "); // Riassembla le parole
  };

  // Ottieni l'immagine di background per la categoria attuale
  const backgroundImage = backgroundImages[category] || bgHeader1;

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const newHeight = Math.max(window.innerHeight - scrollY, 0); // Riduci l'altezza con lo scroll
      const newOpacity = Math.max(1 - scrollY / window.innerHeight, 0); // Riduci l'opacità con lo scroll

      // Calcola la nuova posizione dell'immagine di sfondo
      setHeaderHeight(newHeight);
      setHeaderOpacity(newOpacity);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="w-screen">
      {/* Sezione con l'immagine di background */}
      <div
        className="relative flex items-center justify-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          height: `${headerHeight}px`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center", // Usa la posizione calcolata
          opacity: headerOpacity,
        }}
      >
        {/* Div nero con trasparenza */}
        <div
          className="absolute inset-0 bg-black opacity-50" // Modifica qui il valore di opacity se vuoi più o meno trasparenza
          style={{ zIndex: 1 }} // Assicura che sia sotto l'h1
        ></div>

        <h1
          className="text-white text-5xl text-center md:text-9xl font-bold relative" // Modifica qui zIndex per portarlo sopra il div trasparente
          style={{ opacity: headerOpacity, zIndex: 2 }}
        >
          {formatCategory(category)}
        </h1>
      </div>

      {/* Sezione dei prodotti */}
      <div>
        <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5 p-10">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product, key) => (
              <ProductCart key={key} data={product} />
            ))
          ) : (
            <p>Nessun prodotto disponibile per questa categoria.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Productspage;
