import React from "react";

const CookiePolicy = () => {
  return (
    <div className="container mx-auto p-6 mb-10 md:mb-0 md:h-screen">
      <h1 className="text-3xl font-bold mb-4">Cookie Policy</h1>
      <p className="mb-4">
        Questa pagina spiega cosa sono i cookie, come li usiamo e come puoi
        gestire le tue preferenze sui cookie quando visiti il nostro sito.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-2">Cosa sono i cookie?</h2>
      <p className="mb-4">
        I cookie sono piccoli file di testo che vengono memorizzati sul tuo
        dispositivo quando visiti un sito web. Servono a migliorare l'esperienza
        di navigazione, consentendo al sito di ricordare le tue azioni e
        preferenze nel tempo.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-2">
        Come utilizziamo i cookie?
      </h2>
      <p className="mb-4">
        Usiamo cookie per migliorare l'esperienza dell'utente e per raccogliere
        informazioni statistiche anonime. Questo ci aiuta a capire come i
        visitatori interagiscono con il nostro sito e a migliorare i nostri
        servizi.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-2">
        Gestione delle preferenze
      </h2>
      <p className="mb-4">
        Puoi modificare le tue preferenze sui cookie in qualsiasi momento
        utilizzando il banner presente all'apertura del sito. Inoltre, puoi
        gestire le impostazioni dei cookie direttamente dal tuo browser, dove
        hai la possibilità di rimuovere i cookie già memorizzati.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-2">Contatti</h2>
      <p className="mb-4">
        Se hai domande o preoccupazioni riguardo alla nostra Cookie Policy, non
        esitare a contattarci.
      </p>
    </div>
  );
};

export default CookiePolicy;
