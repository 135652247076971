import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./navbar";
import CartTab from "./cartTab";
import HamburgerMenu from "./hamburgerMenu";

const Layout = () => {
  return (
    <div className="shadow-2xl bg-gradient-to-r from-[#1b558f] to-[#05b4fc]">
      <main className="w-[1200px] max-w-full m-auto p-5">
        <Navbar />
        <Outlet />
      </main>
      <HamburgerMenu />
      <CartTab />
    </div>
  );
};

export default Layout;
