import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { products } from "../products";
import { useDispatch } from "react-redux";
import { addToCart } from "../store/cart";

const Detail = () => {
  const { slug } = useParams();
  const [detail, setDetail] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    const findDetail = products.filter((product) => product.slug === slug);
    if (findDetail.length > 0) {
      setDetail(findDetail[0]);
    } else {
      window.location.href = "/";
    }
  }, [slug]);

  const handleAddToCart = () => {
    dispatch(
      addToCart({
        productId: detail.id,
        quantity: quantity,
      })
    );
  };
  return (
    <div className="w-screen md:h-screen lg:h-auto mb-10 md:mb-0 lg:mb-10">
      <h2 className="text-3xl text-center py-4">Dettagli del prodotto</h2>
      <div className="flex flex-col items-center justify-center mt-5">
        <div className="w-full h-96 flex justify-center items-center">
          <img
            src={detail.image}
            alt=""
            className="w-auto h-full object-cover object-top  p-4"
          />
        </div>
        <div className="flex flex-col items-center justify-center gap-10 mt-10">
          <h1 className="text-xl md:text-4xl uppercase font-bold">
            {detail.name}
          </h1>

          <div className="flex flex-col gap-5">
            <div className="flex gap-2 justify-center items-center">
              <button
                onClick={() => setQuantity(quantity === 1 ? 1 : quantity - 1)}
                className="bg-gray-100 h-full w-10 font-bold text-xl rounded-xl flex justify-center items-center"
              >
                -
              </button>
              <span className="bg-gray-100 h-full w-10 font-bold text-xl rounded-xl flex justify-center items-center">
                {quantity}
              </span>
              <button
                onClick={() => setQuantity(quantity + 1)}
                className="bg-gray-100 h-full w-10 font-bold text-xl rounded-xl flex justify-center items-center"
              >
                +
              </button>
            </div>
            <button
              onClick={handleAddToCart}
              className="bg-slate-900 text-white px-7 py-3 rounded-xl shadow-2xl"
            >
              Aggiungi al carrello
            </button>
          </div>
          <div className="text-center mx-5 md:mx-0 md:w-1/2">
            <p className="text-sm"> {detail.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
