// Porte interne
import pantografata603 from "../src/assets/images/porteInterne/pantografata603.png";
import pantografata613 from "../src/assets/images/porteInterne/pantografata613.png";
import pantografata604 from "../src/assets/images/porteInterne/pantografata604.png";
import pantografata605 from "../src/assets/images/porteInterne/pantografata605.png";
import pantografata606 from "../src/assets/images/porteInterne/pantografata606.png";
import pantografata609 from "../src/assets/images/porteInterne/pantografata609.png";
import pantografata614 from "../src/assets/images/porteInterne/pantografata614.png";
import glamour805 from "../src/assets/images/porteInterne/glamour805.png";
import glamour810 from "../src/assets/images/porteInterne/glamour810.png";
import glamour825 from "../src/assets/images/porteInterne/glamour825.png";
import glamour835 from "../src/assets/images/porteInterne/glamour835.png";
import glamour845 from "../src/assets/images/porteInterne/glamour845.png";
import glamour860 from "../src/assets/images/porteInterne/glamour860.png";
import glamour865 from "../src/assets/images/porteInterne/glamour865.png";
import incisa503 from "../src/assets/images/porteInterne/incisa503.png";
import incisa504 from "../src/assets/images/porteInterne/incisa504.png";
import incisa506 from "../src/assets/images/porteInterne/incisa506.png";
import incisa511 from "../src/assets/images/porteInterne/incisa511.png";
import incisa513 from "../src/assets/images/porteInterne/incisa513.png";
import incisa515 from "../src/assets/images/porteInterne/incisa515.png";
import incisa517 from "../src/assets/images/porteInterne/incisa517.png";
import olimpo103 from "../src/assets/images/porteInterne/olimpo103.png";
import olimpo204 from "../src/assets/images/porteInterne/olimpo204.png";
import classic701 from "../src/assets/images/porteInterne/classic701.png";
import classic703 from "../src/assets/images/porteInterne/classic703.png";
import profiliEracle from "../src/assets/images/porteInterne/profiliEracle.png";
import profiliEra from "../src/assets/images/porteInterne/ProfiliEra.png";
import profiliEros from "../src/assets/images/porteInterne/ProfiliEros.png";
import profiliMarte from "../src/assets/images/porteInterne/ProfiliMArte.png";
import profiliZefiro from "../src/assets/images/porteInterne/ProfiliZefiro.png";
import pantoLight400 from "../src/assets/images/porteInterne/pantoLight400.png";
import pantoLight415 from "../src/assets/images/porteInterne/pantoLight415.png";
import pantaRhei730 from "../src/assets/images/porteInterne/pantaRhei730.png";
import alcantaraOlive from "../src/assets/images/porteInterne/alcantaraOlive.png";
import alcantaraGrafite from "../src/assets/images/porteInterne/alcantaraGrafite.png";
import zeusBiancoPalissandro from "../src/assets/images/porteInterne/zeusBiancoPalissandro.png";
import zeusBiancoMatrix from "../src/assets/images/porteInterne/zeusBiancoMatrix.png";
import zeusGrigioPalissandro from "../src/assets/images/porteInterne/zeusGrigioPalissandro.png";
import zeusGrigioChiaro from "../src/assets/images/porteInterne/zeusGrigioChiaro.png";
import zeusLariceSabbia from "../src/assets/images/porteInterne/zeusLariceSabbia.png";
import zeusNoceCanaletta from "../src/assets/images/porteInterne/zeusNoceCanaletto.png";
import zeusNoceTanganika from "../src/assets/images/porteInterne/zeusNoceTanganika.png";
import zeusOlmoNatura from "../src/assets/images/porteInterne/zeusOlmoNatura.png";
import zeusRovere from "../src/assets/images/porteInterne/zeusRovere.png";
import vetroZeus1v from "../src/assets/images/porteInterne/vetroZeus1V.png";
import vetroZeus1vl from "../src/assets/images/porteInterne/vetroZeus1Vl.png";
import vetroZeus3v from "../src/assets/images/porteInterne/vetroZeus3V.png";
import insertiStrassAfrodite from "../src/assets/images/porteInterne/inserti&StrassAfrodite.png";
import insertiStrassAde from "../src/assets/images/porteInterne/inserti&StrassAde.png";
import insertiStrassAres1 from "../src/assets/images/porteInterne/inserti&StrassAres1.png";
import insertiStrassClio from "../src/assets/images/porteInterne/inserti&StrassClio.png";
import insertiStrassElettra from "../src/assets/images/porteInterne/inserti&StrassElettra.png";
// PVC
import futur70 from "../src/assets/images/serramentiPvc/FUTUR-70.jpg";
import futurAvantgarde from "../src/assets/images/serramentiPvc/FUTUR-Avangarde5_.jpg";
import klimalux from "../src/assets/images/serramentiPvc/Klimalux.jpg";
import klimaluxGold from "../src/assets/images/serramentiPvc/Klimalux_gold.jpg";
import klimaluxAlu from "../src/assets/images/serramentiPvc/klimalux-alu.jpg";
import klimaGlass from "../src/assets/images/serramentiPvc/Klimaglass.jpg";
import secur80 from "../src/assets/images/serramentiPvc/SECUR80.jpg";
import illumiaEvo from "../src/assets/images/serramentiPvc/Illumia_EVO.jpg";
import fibra from "../src/assets/images/serramentiPvc/FIBRA.jpg";
import fibraLux from "../src/assets/images/serramentiPvc/FibraLux.jpg";
import scorrevoleAlzante from "../src/assets/images/serramentiPvc/scorrevole-alzante.jpg";
import scorrevole from "../src/assets/images/serramentiPvc/scorrevole.jpg";
// blindati
import sikura from "../src/assets/images/porteBlindate/sikura.png";
import sikuraPlus from "../src/assets/images/porteBlindate/sikuraPlus.png";
import stylo from "../src/assets/images/porteBlindate/stylo.png";
import stylo2 from "../src/assets/images/porteBlindate/stylo2.png";
import specialPlus from "../src/assets/images/porteBlindate/specialPlus.png";
import specialPlus2 from "../src/assets/images/porteBlindate/specialPlus2.png";
import specialTop from "../src/assets/images/porteBlindate/specialTop.png";
import liberty from "../src/assets/images/porteBlindate/liberty.png";
import liberty2 from "../src/assets/images/porteBlindate/liberty2.png";
import wallZip from "../src/assets/images/porteBlindate/wallZip.png";
import wallZip2 from "../src/assets/images/porteBlindate/wallZip2.png";
import arko from "../src/assets/images/porteBlindate/arko.png";
import arko2 from "../src/assets/images/porteBlindate/arko2.png";
import armor from "../src/assets/images/porteBlindate/armor.png";
import fireBlok from "../src/assets/images/porteBlindate/fireBlok.png";
// Zanzariere
import ambra from "../src/assets/images/zanzariere/ambra.webp";
import switch4 from "../src/assets/images/zanzariere/switch4.webp";
import Verticale50 from "../src/assets/images/zanzariere/50Verticale.webp";
import amby from "../src/assets/images/zanzariere/amby.webp";
import kitlat from "../src/assets/images/zanzariere/kitlat.webp";
import smart from "../src/assets/images/zanzariere/smart.webp";
import smeraldo from "../src/assets/images/zanzariere/smeraldo.webp";
import unikver from "../src/assets/images/zanzariere/unikver.webp";
import voila from "../src/assets/images/zanzariere/voila.webp";
import voila50 from "../src/assets/images/zanzariere/50Voila.webp";
import fissa45 from "../src/assets/images/zanzariere/fissa45.webp";
import scorrevoleIris from "../src/assets/images/zanzariere/ScorrevoleIris.png";
// Legno-Alluminio
import standard from "../src/assets/images/serramentiLegnoAlluminio/standard.png";
// import bilico from "../src/assets/images/serramentiLegnoAlluminio/bilico.png";
import complanare from "../src/assets/images/serramentiLegnoAlluminio/complanare.png";
import drop from "../src/assets/images/serramentiLegnoAlluminio/drop.png";
import flat from "../src/assets/images/serramentiLegnoAlluminio/flat.png";
// import termoscudo from "../src/assets/images/serramentiLegnoAlluminio/termoscudo.png";
// import slim from "../src/assets/images/serramentiLegnoAlluminio/slim.png";
import hsSlim from "../src/assets/images/serramentiLegnoAlluminio/hs-slim.png";
import magis40 from "../src/assets/images/serramentiLegnoAlluminio/magis40.png";

export const products = [
  // {
  //   id: 1,
  //   name: "Rosy",
  //   image: door1,
  //   description: "Porta classica, rotonda, in legno di frassino.",
  //   slug: "rosy",
  //   category: "porte-interne",
  // },
  // {
  //   id: 2,
  //   name: "Lira",
  //   image: door2,
  //   description:
  //     "Porta in laminato, colore bianco matrix, con due inserti in acciaio.",
  //   slug: "lira",
  //   category: "porte-interne",
  // },
  // {
  //   id: 3,
  //   name: "Tania",
  //   image: door3,
  //   description: "Porta in laminato, color noce canaletto.",
  //   slug: "tania",
  //   category: "porte-interne",
  // },
  // PVC
  {
    id: 4,
    name: "Futur 70",
    image: futur70,
    description: `Sistema a doppia guarnizione di battuta;
                  Telaio a 5 camere arrotondato esternamente con 70mm di profondità;
                  Anta a 5 camere semicomplanare versione rotonda con 78mm di profondità;
                  Fermavetro arrotondato;
                  Telaio con rinforzo scatolare chiuso 40mm x 25mm in acciaio zincato con spessore minimo 15/10 a garanzia di un solido fissaggio dei riscontri di sicurezza, dei rinforzi e delle cerniere;
                  Anta con rinforzo scatolare aperto 40mm x 25mm in acciaio zincato con spessore minimo 15/10 a garanzia di un’elevata stabilità;
                  Profili in PVC in Classe A secondo la normativa DIN EN 12608 spessore pareti esterne 3 mm (+/- 0,2 mm);
                  Profili in PVC in Classe S (clima severo) secondo la normativa UNI EN 12608F`,
    slug: "Futur 70",
    category: "serramenti-PVC",
  },
  {
    id: 5,
    name: "Futur Avantgarde 5",
    image: futurAvantgarde,
    description: `Sistema a doppia guarnizione di battuta;
                  Telaio a 5 camere arrotondato esternamente con 70mm di profondità;
                  Anta a 5 camere versione rotonda con 70mm di profondità;
                  Fermavetro arrotondato;
                  Telaio con rinforzo scatolare chiuso 40mm x 25mm in acciaio zincato con spessore minimo 15/10 a garanzia di un solido fissaggio dei riscontri di sicurezza, dei rinforzi e delle cerniere;
                  Anta con rinforzo scatolare 40mm x 25mm in acciaio zincato con spessore minimo 15/10 a garanzia di un’ elevata stabilità;
                  Profili in PVC di Classe A secondo la normati va DIN EN 12608-2003 spessore minimo pareti esterne 2,8mm;
                  Profili in PVC di Classe S (clima severo) secondo la normati va UNI EN 12608- 2005`,
    slug: "Futur Avantgarde 5",
    category: "serramenti-PVC",
  },
  {
    id: 6,
    name: "Klimalux",
    image: klimalux,
    description: `Sistema a tripla guarnizione di battuta;
                  Telaio a 7 camere squadrato esternamente con 80mm di profondità;
                  Anta a 7 camere versione squadrata con 80mm di profondità;
                  Fermavetro squadrato;
                  Telaio con rinforzo scatolare chiuso 30mm x 25mm in acciaio zincato con spessore minimo 15/10 a garanzia di un solido fissaggio dei riscontri di sicurezza, dei rinforzi e delle cerniere;
                  Anta con rinforzo 32,5mm x 27mm in acciaio zincato con spessore minimo 15/10;
                  Profili in PVC in Classe A secondo la normativa DIN EN 12608 spessore pareti esterne 3mm (+/- 0,2mm);
                  Profili in PVC in Classe S (clima severo) secondo la normativa UNI EN 12608;`,
    slug: "Klimalux",
    category: "serramenti-PVC",
  },
  {
    id: 7,
    name: "Klimalux Gold",
    image: klimaluxGold,
    description: `Sistema a tripla guarnizione di battuta;
                  Telaio a 7 camere squadrato esternamente con 80mm di profondità;
                  Anta a 7 camere versione squadrata con 80mm di profondità;
                  Fermavetro squadrato;
                  Telaio con rinforzo scatolare chiuso 30mm x 25mm in acciaio zincato con spessore minimo 15/10 a garanzia di un solido fissaggio dei riscontri di sicurezza, dei rinforzi e delle cerniere;
                  Anta con rinforzo 32,5mm x 27mm in acciaio zincato con spessore minimo
                  15/10;
                  Profili in PVC in Classe A secondo la normativa DIN EN 12608 spessore pareti esterne 3mm (+/- 0,2mm);
                  Profili in PVC in Classe S (clima severo) secondo la normativa UNI EN 12608;`,
    slug: "Klimalux Gold",
    category: "serramenti-PVC",
  },
  {
    id: 8,
    name: "Klimalux ALU",
    image: klimaluxAlu,
    description: `Sistema a tripla guarnizione di battuta con rivestimento esterno in alluminio;
                  Telaio a 7 camere squadrato esternamente con 80mm di profondità;
                  Anta a 7 camere versione squadrata con 80mm di profondità;
                  Fermavetro squadrato;
                  Telaio con rinforzo scatolare chiuso 30mm x 25mm in acciaio zincato con spessore minimo 15/10 a garanzia di un solido fissaggio dei riscontri di sicurezza, dei rinforzi e delle cerniere;
                  Anta con rinforzo 32,5mm x 27mm in acciaio zincato con spessore minimo 15/10;
                  Profili in PVC in Classe A secondo la normativa DIN EN 12608 spessore pareti esterne 3mm (+/- 0,2mm);
                  Profili in PVC in Classe S (clima severo) secondo la normativa UNI EN 12608;`,
    slug: "Klimalux ALU",
    category: "serramenti-PVC",
  },
  {
    id: 9,
    name: "KlimaGlass",
    image: klimaGlass,
    description: `Sistema a tripla guarnizione di battuta;
                  Telaio a 7 camere squadrato esternamente con 80mm di profondità;
                  Anta realizzata in vetro e PVC a 5 camere versione squadrata con 80mm di profondità;
                  Assenza di listelli interni fermavetro;
                  Telaio con rinforzo scatolare chiuso 30mm x 25mm in acciaio zincato con spessore minimo 15/10 a garanzia di un solido fissaggio dei riscontri di sicurezza, dei rinforzi e delle cerniere;
                  Esternamente, il profilo dell’anta è totalmente coperto dal vetro con smaltatura perimetrale di colore nero, per un effetto tutto vetro;
                  Vetro strutturale incollato al profilo anta;  
                  Profili in PVC in Classe A secondo la normativa DIN EN 12608 spessore pareti esterne 3 mm (+/- 0,2 mm);
                  Profili in PVC in Classe S (clima severo) secondo la normativa UNI EN 12608`,
    slug: "KlimaGlass",
    category: "serramenti-PVC",
  },
  {
    id: 10,
    name: "Secur80",
    image: secur80,
    description: `Sistema a tripla guarnizione di battuta;
                  Telaio a 7 camere squadrato esternamente con 80mm di profondità;
                  Anta a 7 camere versione squadrata con 80mm di profondità;
                  Vetrocamera 55.6 || 18 || 33.1 be + Argon – Canalina Termica Warm-Edge;
                  Vetrocamera in Classe P5A;
                  Particolare sistema di incollaggio a rapida presa a base polimerica;
                  Fermavetro squadrato;
                  Telaio con rinforzo scatolare chiuso 30mm x 25mm in acciaio zincato con spessore minimo 15/10 a garanzia di un solido fissaggio dei riscontri di sicurezza, dei rinforzi e delle cerniere
                  Anta con rinforzo 32,5mm x 27mm in acciaio zincato con spessore minimo 15/10;
                  Rinforzi supplementari in acciaio zincato su Profili Anta e Telaio;
                  Profili in PVC in Classe A secondo la normati va DIN EN 12608 spessore pareti esterne 3mm (+/- 0,2mm);
                  Profili in PVC in Classe S (clima severo) secondo la normati va UNI EN 12608;`,
    slug: "Secur80",
    category: "serramenti-PVC",
  },
  {
    id: 11,
    name: "Illumia EVO",
    image: illumiaEvo,
    description: `Sistema a tripla guarnizione di battuta;
                  Telaio a 7 camere squadrato esternamente con 80mm di profondità;
                  Anta a 7 camere versione squadrata con 80mm di profondità;
                  Le ridotte dimensioni dei profili anta, telaio e riporto centrale, garantiscono il passaggio di una maggiore quantità di luce;
                  Telaio con rinforzo scatolare chiuso 30mm x 25mm in acciaio zincato con spessore minimo 15/10 a garanzia di un solido fissaggio dei riscontri di sicurezza, dei rinforzi e delle cerniere;
                  Anta con rinforzo 32,5mm x 25mm in acciaio zincato con spessore minimo
                  15/10;
                  Profili in PVC in Classe A secondo la normativa DIN EN 12608 spessore pareti esterne 3mm (+/- 0,2mm);
                  Profili in PVC in Classe S (clima severo) secondo la normativa UNI EN 12608;`,
    slug: "Illumia EVO",
    category: "serramenti-PVC",
  },
  {
    id: 12,
    name: "Fibra",
    image: fibra,
    description: `Sistema in vetroresina a tripla guarnizione di battuta;
                  Telaio squadrato esternamente con 95 mm di profondità;
                  Anta a sezione squadrata con 88mm di profondità;
                  Profili telaio ed anta con isolamento in polistirene espanso sinterizzato con grafite;
                  Superficie vetrata più ampia grazie all’anta TOTALMENTE nascosta;
                  Assenza di listelli interni di fermavetro;
                  Allestimento standard con ferramenta a scomparsa e martellina Secustik squadrata;
                  Il parti colare sistema costruttivo, a tripla guarnizione di battuta, garantisce un’elevata resistenza al vento oltre ad un ottimo isolamento acustico;
                  La specifica tecnica di incollaggio del profilo anta lungo tutto il perimetro del vetro e l’elevato grado di resistenza meccanica intrinseca del profilo in vetroresina, assicurano un’ottima stabilità ed un’elevata resistenza alla torsione;`,
    slug: "Fibra",
    category: "serramenti-PVC",
  },
  {
    id: 13,
    name: "FibraLux",
    image: fibraLux,
    description: `Sistema scorrevole alzante in vetroresina
                  Telaio 2 binari a 2 camere con 185mm di profondità;
                  Anta a 3 camere squadrata esternamente con 78mm di profondità.
                  ingombro 52mm
                  Fermavetro squadrato con angolo 90° per vetrocamere da 32mm o 48mm;
                  Telaio con rinforzo in alluminio dimensione 80mm x 30mm;
                  Soglia ribassata termica in vetroresina. Internamente può essere totalmente
                  installata a filo pavimento;
                  Ferramenta perimetrale con carrelli fino a 450 kg di portata per anta: `,
    slug: "FibraLux",
    category: "serramenti-PVC",
  },
  {
    id: 14,
    name: "Scorrevole Alzante",
    image: scorrevoleAlzante,
    description: `Sistema scorrevole
                  Telaio 2 binari a 3 camere con 167mm di profondità;
                  Anta a 3 camere squadrata esternamente con 70mm di profondità;
                  Fermavetro arrotondato o squadrato;
                  Telaio con rinforzo tubolare in alluminio a taglio termico dimensione 136mm x 38mm;
                  Anta con rinforzo in acciaio zincato 40mm x 48mm spessore 25/10;
                  Profili in PVC in Classe A secondo la normativa DIN EN 12608 spessore
                  minimo pareti esterne 3mm (+/- 0,2mm);
                  Profili in PVC in Classe S (clima severo) secondo la normativa UNI EN 12608;`,
    slug: "Scorrevole Alzante",
    category: "serramenti-PVC",
  },
  {
    id: 15,
    name: "Scorrevole in Linea",
    image: scorrevole,
    description: `Sistema scorrevole leggero;
                  Telaio 2 binari a 3 camere con 70mm di profondità;
                  Anta a 2 camere arrotondata esternamente con 46mm di profondità;
                  Fermavetro arrotondato;
                  Telaio con rinforzo scatolare chiuso 40mm x 10mm in acciaio zincato con spessore minimo 15/10;
                  Anta con rinforzo scatolare aperto 33mm x 27mm in acciaio zincato con
                  spessore minimo 15/10;
                  Profili in PVC in Classe A secondo la normativa DIN EN 12608 spessore
                  minimo pareti esterne 3mm (+/- 0,2mm);
                  Profili in PVC in Classe S (clima severo) secondo la normativa UNI EN 12608;`,
    slug: "Scorrevole in Linea",
    category: "serramenti-PVC",
  },
  // Porte blindate
  {
    id: 16,
    name: "Sikura",
    image: sikura,
    description: `Con il modello Sikura, la Aerre Porte ha dato la massima dimostrazione di come sia 
                  realmente possibile realizzare una porta blindata di elevata qualità, con standard di sicurezza
                  di assoluto riferimento sul mercato, attestandosi su un elevato rapporto qualità/prezzo.
                  `,
    slug: "Sikura",
    category: "porte-blindate",
  },
  {
    id: 17,
    name: "Sikura Plus",
    image: sikuraPlus,
    description: `Il modello Sikura mette i muscoli. Aumentata la classe di effrazione a 4, doppia guarnizione (anta + telaio) e doppia coibentazione 
                  interna all’omega e tappetino fonoassorbente 
                  garantiscono valori importanti come la trasmittanza termica ridotta a 1.29 garantendo così un
                  minor dispendio energetico all’interno dell’abitazione, e l’abbattimento acustico aumentato
                  a 41 dB così da poter isolare al meglio la vostra abitazione da rumori esterni.`,
    slug: "SikuraPlus",
    category: "porte-blindate",
  },
  {
    id: 18,
    name: "Stylo",
    image: stylo,
    description: `L’abilità progettuale e costruttiva della Aerre Porte si manifesta nella realizzazione del modello
                  Stylo che rappresenta il giusto mix in termini di eleganza e preziosità delle forme, coniugando
                  al meglio compattezza e resistenza alle sollecitazioni.`,
    slug: "Stylo",
    category: "porte-blindate",
  },
  {
    id: 19,
    name: "Stylo 2",
    image: stylo2,
    description: `L’abilità progettuale e costruttiva della Aerre Porte si manifesta nella realizzazione del modello
                  Stylo che rappresenta il giusto mix in termini di eleganza e preziosità delle forme, coniugando
                  al meglio compattezza e resistenza alle sollecitazioni.`,
    slug: "Stylo2",
    category: "porte-blindate",
  },
  {
    id: 20,
    name: "Special Plus",
    image: specialPlus,
    description: `Special Plus è la porta che racchiude in sè bellezza,forza,innovazione, e cura nei dettagli.
                  Un modello unico, dalle alte prestazioni tecniche e funzionali. Oltre ad elevati standard di
                  sicurezza garantisce infatti ottimi abbattimenti acustici e termici.`,
    slug: "SpecialPlus",
    category: "porte-blindate",
  },
  {
    id: 21,
    name: "Special Plus 2",
    image: specialPlus2,
    description: `Special Plus è la porta che racchiude in sè bellezza,forza,innovazione, e cura nei dettagli.
                  Un modello unico, dalle alte prestazioni tecniche e funzionali. Oltre ad elevati standard di
                  sicurezza garantisce infatti ottimi abbattimenti acustici e termici.`,
    slug: "SpecialPlus2",
    category: "porte-blindate",
  },
  {
    id: 22,
    name: "Special Top",
    image: specialTop,
    description: `Special Top è la massima espressione della produzione di AERRE PORTE, il Top di Gamma. E’
                  una porta di altissima sicurezza la cui caratteristica peculiare è la maggiore resistenza alle 
                  effrazioni, un migliore abbattimento acustico (44 dB) e un ottimo livello di isolamento termico (1.60
                  W/m2k). la soluzione perfetta per godervi nel massimo comfort la vostra casa.`,
    slug: "SpecialTop",
    category: "porte-blindate",
  },
  {
    id: 23,
    name: "Liberty",
    image: liberty,
    description: `Una soluzione progettuale completamente innovativa che propone al cliente una soluzione 
                  tecnica come l’apertura verso l’esterno con cablatura inversa dell’anta. Libertà, sicurezza e 
                  versalità, il modello Liberty rappresenta tutto questo.`,
    slug: "Liberty",
    category: "porte-blindate",
  },
  {
    id: 24,
    name: "Liberty 2",
    image: liberty2,
    description: `Una soluzione progettuale completamente innovativa che propone al cliente una soluzione 
                  tecnica come l’apertura verso l’esterno con cablatura inversa dell’anta. Libertà, sicurezza e 
                  versalità, il modello Liberty rappresenta tutto questo.`,
    slug: "Liberty2",
    category: "porte-blindate",
  },
  {
    id: 25,
    name: "Wall Zip",
    image: wallZip,
    description: `La porta WALL-ZIP, grazie alle sue cerniere a scomparsa, può essere realizzata a
                  filomuro per una migliore integrazione con gli ambienti moderni,le porte interne e le
                  pareti.`,
    slug: "WallZip",
    category: "porte-blindate",
  },
  {
    id: 26,
    name: "Wall Zip 2",
    image: wallZip2,
    description: `La porta WALL-ZIP, grazie alle sue cerniere a scomparsa, può essere realizzata a
                  filomuro per una migliore integrazione con gli ambienti moderni,le porte interne e le
                  pareti.`,
    slug: "WallZip2",
    category: "porte-blindate",
  },
  {
    id: 27,
    name: "Arko",
    image: arko,
    description: `La Arko è il fiore all’occhiello della produzione Aerre Porte.
                  In questo modello si amalgamano sapientemente la tradizionale abilità artigianale, la bellezza
                  fuori dal tempo, la robustezza e sicurezza ai livelli di eccellenza.`,
    slug: "Arko",
    category: "porte-blindate",
  },
  {
    id: 28,
    name: "Arko 2",
    image: arko2,
    description: `La Arko è il fiore all’occhiello della produzione Aerre Porte.
                  In questo modello si amalgamano sapientemente la tradizionale abilità artigianale, la bellezza
                  fuori dal tempo, la robustezza e sicurezza ai livelli di eccellenza.`,
    slug: "Arko2",
    category: "porte-blindate",
  },
  {
    id: 29,
    name: "Armor",
    image: armor,
    description: `Armor, porta blindata classe 5 antieffrazione. La porta blindata bella fuori e dura dentro. Il 
                  perfetto incontro tra ricerca dei materiale e voglia di innovazione, porta l’AERRE Porte ad essere
                  fra le prime aziende italiane a vantare un traguardo simile.`,
    slug: "Armor",
    category: "porte-blindate",
  },
  {
    id: 30,
    name: "Fire Blok",
    image: fireBlok,
    description: `Siamo lieti di presentare FIRE BLOK Porta Blindata classe 4 antieffrazione e resistente
                  al fuoco 60’ minuti offrendovi così sicurezza e affidabilità anche nelle situazioni più
                  estreme.`,
    slug: "FireBlok",
    category: "porte-blindate",
  },
  // Zanzariere
  {
    id: 31,
    name: "Avvolgente Ambra",
    image: ambra,
    description: `Zanzariera a molla avvolgente verticale per finestre.`,
    slug: "Ambra",
    category: "zanzariere",
  },
  {
    id: 32,
    name: "Avvolgente Switch 4",
    image: switch4,
    description: `Zanzariera avvolgente per portefinestre con binario inferiore ultra sottile e scorrimento laterale frizionato.`,
    slug: "Switch4",
    category: "zanzariere",
  },
  {
    id: 33,
    name: "Incasso 50 Verticale",
    image: Verticale50,
    description: `Zanzariera a molla avvolgente verticale ad incasso per finestre.`,
    slug: "Verticale50",
    category: "zanzariere",
  },
  {
    id: 34,
    name: "Plissettata Amby",
    image: amby,
    description: `Zanzariera plissettata per finestre e portefinestre a scorrimento laterale.`,
    slug: "Amby",
    category: "zanzariere",
  },
  {
    id: 35,
    name: "Avvolgente Kitlat",
    image: kitlat,
    description: `Zanzariera a molla in alluminio per portefinestre.`,
    slug: "Kitlat",
    category: "zanzariere",
  },
  {
    id: 36,
    name: "Plissettata Smart",
    image: smart,
    description: `Zanzariera plissettata in alluminio a scorrimento laterale per finestre e portefinestre.`,
    slug: "Smart",
    category: "zanzariere",
  },
  {
    id: 37,
    name: "Avvolgente Smeraldo",
    image: smeraldo,
    description: `Zanzariera a molla avvolgente verticale per finestre con guide telescopiche. Cassonetto tondo da 50mm e 40mm.`,
    slug: "Smeraldo",
    category: "zanzariere",
  },
  {
    id: 38,
    name: "Avvolgente Unikver",
    image: unikver,
    description: `Zanzariera a molla avvolgente verticale per finestre.`,
    slug: "Unikver",
    category: "zanzariere",
  },
  {
    id: 39,
    name: "Plissettata Voilà",
    image: voila,
    description: `Zanzariera plissettata per portefinestre a scorrimento laterale.`,
    slug: "Voilà",
    category: "zanzariere",
  },
  {
    id: 40,
    name: "Incasso 50 Voilà",
    image: voila50,
    description: `Zanzariera plissettata in alluminio per portefinestre con guida inferiore sottile. Montaggio ad incasso da 50. Realizzabile a 1 e 2 ante.`,
    slug: "Voilà50",
    category: "zanzariere",
  },
  {
    id: 41,
    name: "Fissa 45",
    image: fissa45,
    description: `Zanzariera fissa per finestre con taglio dei profili a 45 gradi.`,
    slug: "Fissa45",
    category: "zanzariere",
  },
  {
    id: 42,
    name: "Scorrevole Iris",
    image: scorrevoleIris,
    description: `Zanzariera scorrevole in alluminio per finestre con pannelli a movimentazione verticale.`,
    slug: "Iris",
    category: "zanzariere",
  },
  // Porte interne
  {
    id: 43,
    name: "Pantografata PP 603",
    image: pantografata603,
    description: "Tutto legno: Piatto o tondo.",
    slug: "PantografataPP603",
    category: "porte-interne",
  },
  {
    id: 44,
    name: "Pantografata PP 604",
    image: pantografata604,
    description: "Tutto legno: Piatto o tondo.",
    slug: "PantografataPP604",
    category: "porte-interne",
  },
  {
    id: 45,
    name: "Pantografata PP 605",
    image: pantografata605,
    description: "Tutto legno: Piatto o tondo.",
    slug: "PantografataPP605",
    category: "porte-interne",
  },
  {
    id: 46,
    name: "Pantografata PP 606",
    image: pantografata606,
    description: "Tutto legno: Piatto o tondo.",
    slug: "PantografataPP606",
    category: "porte-interne",
  },
  {
    id: 47,
    name: "Pantografata PP 609",
    image: pantografata609,
    description: "Tutto legno: Piatto o tondo.",
    slug: "PantografataPP609",
    category: "porte-interne",
  },
  {
    id: 48,
    name: "Pantografata PP 613",
    image: pantografata613,
    description: "Tutto legno: Piatto o tondo.",
    slug: "PantografataPP613",
    category: "porte-interne",
  },
  {
    id: 49,
    name: "Pantografata PP 614",
    image: pantografata614,
    description: "Tutto legno: Piatto o tondo.",
    slug: "PantografataPP614",
    category: "porte-interne",
  },
  {
    id: 50,
    name: "Glamour GL 805",
    image: glamour805,
    description: "Tutto legno: Piatto o tondo.",
    slug: "GlamourGL805",
    category: "porte-interne",
  },
  {
    id: 51,
    name: "Glamour GL 810",
    image: glamour810,
    description: "Tutto legno: Piatto o tondo.",
    slug: "GlamourGL810",
    category: "porte-interne",
  },
  {
    id: 52,
    name: "Glamour GL 825",
    image: glamour825,
    description: "Tutto legno: Piatto o tondo.",
    slug: "GlamourGL825",
    category: "porte-interne",
  },
  {
    id: 53,
    name: "Glamour GL 835",
    image: glamour835,
    description: "Tutto legno: Piatto o tondo.",
    slug: "GlamourGL835",
    category: "porte-interne",
  },
  {
    id: 54,
    name: "Glamour GL 845",
    image: glamour845,
    description: "Tutto legno: Piatto o tondo.",
    slug: "GlamourGL845",
    category: "porte-interne",
  },
  {
    id: 55,
    name: "Glamour GL 860",
    image: glamour860,
    description: "Tutto legno: Piatto o tondo.",
    slug: "GlamourGL860",
    category: "porte-interne",
  },
  {
    id: 56,
    name: "Glamour GL 865",
    image: glamour865,
    description: "Tutto legno: Piatto o tondo.",
    slug: "GlamourGL865",
    category: "porte-interne",
  },
  {
    id: 57,
    name: "Incisa PS 503",
    image: incisa503,
    description: "Tutto legno: Piatto o tondo.",
    slug: "IncisaPS503",
    category: "porte-interne",
  },
  {
    id: 58,
    name: "Incisa PS 504",
    image: incisa504,
    description: "Tutto legno: Piatto o tondo.",
    slug: "IncisaPS504",
    category: "porte-interne",
  },
  {
    id: 59,
    name: "Incisa PS 506",
    image: incisa506,
    description: "Tutto legno: Piatto o tondo.",
    slug: "IncisaPS506",
    category: "porte-interne",
  },
  {
    id: 60,
    name: "Incisa PS 511",
    image: incisa511,
    description: "Tutto legno: Piatto o tondo.",
    slug: "IncisaPS511",
    category: "porte-interne",
  },
  {
    id: 61,
    name: "Incisa PS 513",
    image: incisa513,
    description: "Tutto legno: Piatto o tondo.",
    slug: "IncisaPS513",
    category: "porte-interne",
  },
  {
    id: 62,
    name: "Incisa PS 515",
    image: incisa515,
    description: "Tutto legno: Piatto o tondo.",
    slug: "IncisaPS515",
    category: "porte-interne",
  },
  {
    id: 63,
    name: "Incisa PS 517",
    image: incisa517,
    description: "Tutto legno: Piatto o tondo.",
    slug: "IncisaPS517",
    category: "porte-interne",
  },
  {
    id: 64,
    name: "Olimpo OL 103",
    image: olimpo103,
    description: "Piatto legno.",
    slug: "OlimpoOL103",
    category: "porte-interne",
  },
  {
    id: 65,
    name: "Olimpo OL 204",
    image: olimpo204,
    description: "Piatto legno.",
    slug: "OlimpoOL204",
    category: "porte-interne",
  },
  {
    id: 66,
    name: "Classic PC 701",
    image: classic701,
    description: "Tutto legno: Piatto o Tondo.",
    slug: "ClassicPC701",
    category: "porte-interne",
  },
  {
    id: 67,
    name: "Classic PC 703",
    image: classic703,
    description: "Tutto legno: Piatto o Tondo.",
    slug: "ClassicPC703",
    category: "porte-interne",
  },
  {
    id: 68,
    name: "Profili Era",
    image: profiliEra,
    description: "Piatto legno.",
    slug: "ProfiliEra",
    category: "porte-interne",
  },
  {
    id: 69,
    name: "Profili Eracle",
    image: profiliEracle,
    description: "Piatto legno.",
    slug: "ProfiliEracle",
    category: "porte-interne",
  },
  {
    id: 70,
    name: "Profili Eros",
    image: profiliEros,
    description: "Piatto legno.",
    slug: "ProfiliEros",
    category: "porte-interne",
  },
  {
    id: 71,
    name: "Profili Marte",
    image: profiliMarte,
    description: "Piatto legno.",
    slug: "ProfiliMarte",
    category: "porte-interne",
  },
  {
    id: 72,
    name: "Profili Zefiro",
    image: profiliZefiro,
    description: "Piatto legno.",
    slug: "ProfiliZefiro",
    category: "porte-interne",
  },
  {
    id: 73,
    name: "Panto Light PL 400",
    image: pantoLight400,
    description: "Piatto legno.",
    slug: "PantoLightPL400",
    category: "porte-interne",
  },
  {
    id: 74,
    name: "Panto Light PL 415",
    image: pantoLight415,
    description: "Piatto legno.",
    slug: "PantoLightPL415",
    category: "porte-interne",
  },
  {
    id: 75,
    name: "Panta Rhei PR 730",
    image: pantaRhei730,
    description: "Piatto legno.",
    slug: "PantaRheiPR730",
    category: "porte-interne",
  },
  {
    id: 76,
    name: "Alcantara Olive",
    image: alcantaraOlive,
    description: "Piatto legno.",
    slug: "AlcantaraOlive",
    category: "porte-interne",
  },
  {
    id: 77,
    name: "Alcantara Grafite",
    image: alcantaraGrafite,
    description: "Piatto legno.",
    slug: "AlcantaraGrafite",
    category: "porte-interne",
  },
  {
    id: 78,
    name: "Zeus Bianco Palissandro",
    image: zeusBiancoPalissandro,
    description: "Piatto legno.",
    slug: "ZeusBiancoPalissandro",
    category: "porte-interne",
  },
  {
    id: 79,
    name: "Zeus Bianco Matrix",
    image: zeusBiancoMatrix,
    description: "Piatto legno.",
    slug: "ZeusBiancoMatrix",
    category: "porte-interne",
  },
  {
    id: 80,
    name: "Zeus Grigio Chiaro",
    image: zeusGrigioChiaro,
    description: "Piatto legno.",
    slug: "ZeusGrigioChiaro",
    category: "porte-interne",
  },
  {
    id: 81,
    name: "Zeus Grigio Palissandro",
    image: zeusGrigioPalissandro,
    description: "Piatto legno.",
    slug: "ZeusGrigioPalissandro",
    category: "porte-interne",
  },
  {
    id: 82,
    name: "Zeus Larice Sabbia",
    image: zeusLariceSabbia,
    description: "Piatto legno.",
    slug: "ZeusLariceSabbia",
    category: "porte-interne",
  },
  {
    id: 83,
    name: "Zeus Noce Canaletta",
    image: zeusNoceCanaletta,
    description: "Piatto legno.",
    slug: "ZeusNoceCanaletta",
    category: "porte-interne",
  },
  {
    id: 84,
    name: "Zeus Noce Tanganika",
    image: zeusNoceTanganika,
    description: "Piatto legno.",
    slug: "ZeusNoceTanganika",
    category: "porte-interne",
  },
  {
    id: 85,
    name: "Zeus Olmo Natura",
    image: zeusOlmoNatura,
    description: "Piatto legno.",
    slug: "ZeusOlmoNatura",
    category: "porte-interne",
  },
  {
    id: 86,
    name: "Zeus Rovere",
    image: zeusRovere,
    description: "Piatto legno.",
    slug: "ZeusRovere",
    category: "porte-interne",
  },
  {
    id: 87,
    name: "Vetro Zeus 1 V",
    image: vetroZeus1v,
    description: "Piatto legno.",
    slug: "VetroZeus1V",
    category: "porte-interne",
  },
  {
    id: 88,
    name: "Vetro Zeus 1 VL",
    image: vetroZeus1vl,
    description: "Piatto legno.",
    slug: "VetroZeus1VL",
    category: "porte-interne",
  },
  {
    id: 89,
    name: "Vetro Zeus 3 V",
    image: vetroZeus3v,
    description: "Piatto legno.",
    slug: "VetroZeus3V",
    category: "porte-interne",
  },
  {
    id: 90,
    name: "Inserti & Strass Ade",
    image: insertiStrassAde,
    description: "Piatto legno.",
    slug: "Inserti&StrassAde",
    category: "porte-interne",
  },
  {
    id: 91,
    name: "Inserti & Strass Afrodite",
    image: insertiStrassAfrodite,
    description: "Piatto legno.",
    slug: "Inserti&StrassAfrodite",
    category: "porte-interne",
  },
  {
    id: 92,
    name: "Inserti & Strass Ares 1",
    image: insertiStrassAres1,
    description: "Piatto legno.",
    slug: "Inserti&StrassAres1",
    category: "porte-interne",
  },
  {
    id: 93,
    name: "Inserti & Strass Clio",
    image: insertiStrassClio,
    description: "Piatto legno.",
    slug: "Inserti&StrassClio",
    category: "porte-interne",
  },
  {
    id: 94,
    name: "Inserti & Strass Elettra",
    image: insertiStrassElettra,
    description: "Piatto legno.",
    slug: "Inserti&Strasselettra",
    category: "porte-interne",
  },
  // Legno-Alluminio
  {
    id: 95,
    name: "Standard",
    image: standard,
    description:
      "Isolamento termico: UW= 1,2 W/m²K .  Permeabilità all'aria: CLASSE 4. Tenuta all'acqua: CLASSE E1050. Resistenza al carico del vento: CLASSE C5.",
    slug: "Standard",
    category: "serramenti-legno-alluminio",
  },
  // {
  //   id: 96,
  //   name: "Bilico",
  //   image: bilico,
  //   description: "Piatto legno.",
  //   slug: "Bilico",
  //   category: "serramenti-legno-alluminio",
  // },
  {
    id: 97,
    name: "Complanare",
    image: complanare,
    description:
      "Isolamento termico: UW= 0,8 W/m²K .  Permeabilità all'aria: CLASSE 4. Tenuta all'acqua: CLASSE E1350. Resistenza al carico del vento: CLASSE C5.",
    slug: "Complanare",
    category: "serramenti-legno-alluminio",
  },
  {
    id: 98,
    name: "Drop",
    image: drop,
    description:
      "Isolamento termico: UW= 1,2 W/m²K .  Permeabilità all'aria: CLASSE 4. Tenuta all'acqua: CLASSE E1050. Resistenza al carico del vento: CLASSE C5.",
    slug: "Drop",
    category: "serramenti-legno-alluminio",
  },
  {
    id: 99,
    name: "Flat",
    image: flat,
    description:
      "Isolamento termico: UW= 0,8 W/m²K .  Permeabilità all'aria: CLASSE 4. Tenuta all'acqua: CLASSE E1050. Resistenza al carico del vento: CLASSE C5.",
    slug: "Flat",
    category: "serramenti-legno-alluminio",
  },
  // {
  //   id: 100,
  //   name: "Termoscudo",
  //   image: termoscudo,
  //   description: "Piatto legno.",
  //   slug: "Termoscudo",
  //   category: "serramenti-legno-alluminio",
  // },
  // {
  //   id: 101,
  //   name: "Slim",
  //   image: slim,
  //   description: "Piatto legno.",
  //   slug: "Slim",
  //   category: "serramenti-legno-alluminio",
  // },
  {
    id: 102,
    name: "HS-Slim",
    image: hsSlim,
    description:
      "Isolamento termico: UW= 0,76 W/m²K .  Permeabilità all'aria: CLASSE 4. Tenuta all'acqua: CLASSE 8A. Resistenza al carico del vento: CLASSE C4.",
    slug: "HS-Slim",
    category: "serramenti-legno-alluminio",
  },
  {
    id: 103,
    name: "Magis 40",
    image: magis40,
    description:
      "Isolamento termico: UW= 0,71 W/m²K .  Permeabilità all'aria: CLASSE 4. Tenuta all'acqua: CLASSE E1350. Resistenza al carico del vento: CLASSE C5.",
    slug: "Magis 40",
    category: "serramenti-legno-alluminio",
  },
];
