import React from "react";
import whatsappIcon from "../../assets/icons/WhatsApp.svg 1.png";
import { motion } from "framer-motion";
import instagramLogo from "../../assets/images/Instagram_icon.png.webp";
import facebookLogo from "../../assets/images/Facebook_logo_(square).png";

const ContactUs = () => {
  const textVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1, transition: { duration: 1.5 } },
  };
  const textVariants2 = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1, transition: { duration: 2 } },
  };
  return (
    <section
      id="contactUs"
      className="h-[600px] bg-white flex flex-col py-20 items-center gap-20"
    >
      <motion.h1
        variants={textVariants}
        initial="hidden"
        whileInView="visible"
        className="font-bold text-5xl"
      >
        Contattaci
      </motion.h1>
      <motion.div
        variants={textVariants2}
        initial="hidden"
        whileInView="visible"
        className="flex flex-col gap-5"
      >
        <div>
          <a
            href="https://wa.me/3486624905"
            target="_blank"
            rel="noopener noreferrer"
            className="flex gap-5 items-center"
          >
            <img
              src={whatsappIcon}
              className="w-10 h-11"
              alt="Contattaci su WhatsApp"
            />
            <p className="font-bold md:text-xl">Contattaci su Whatsapp</p>
          </a>
        </div>
        <div>
          <a href="tel:+393486624905" className="flex items-center gap-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
              />
            </svg>

            <p className="font-bold md:text-xl">3486624905</p>
          </a>
        </div>
        <div>
          <a
            href="mailto:bevilacquaserramenti@gmail.com?subject=Richiesta%20informazioni&body=Ciao,%20vorrei%20informazioni%20riguardo..."
            className="flex items-center gap-5"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
              />
            </svg>
            <p className="font-bold md:text-xl">
              bevilacquaserramenti@gmail.com
            </p>
          </a>
        </div>
        <div className="flex gap-5 flex-col">
          <a
            href="https://www.instagram.com/bevilacquaserramenti/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex gap-4 items-center">
              <img
                src={instagramLogo}
                className="w-10 h-10"
                alt="instagram logo"
              />
              <p className="font-bold">bevilacquaserramenti</p>
            </div>
          </a>

          <a
            href="https://www.facebook.com/profile.php?id=61565928853738"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex gap-4 items-center">
              <img
                src={facebookLogo}
                className="w-10 h-10"
                alt="facebook logo"
              />
              <p className="font-bold">bevilacquaserramenti</p>
            </div>
          </a>
        </div>
      </motion.div>
    </section>
  );
};

export default ContactUs;
