import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CartItem from "./cartItem";
import { toggleStatusTab, clearCart } from "../store/cart";
import emailjs from "@emailjs/browser";
import { products } from "../products";

const CartTab = () => {
  const carts = useSelector((store) => store.cart.items);
  const statusTab = useSelector((store) => store.cart.statusTab);
  const dispatch = useDispatch();
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  // Controlla se il carrello è vuoto
  const isCartEmpty = carts.length === 0;

  // Funzione per inviare l'email con emailJS
  const sendEmail = (e) => {
    e.preventDefault(); // Previene l'invio del form automatico

    // Suddivisione dei prodotti per categorie
    const categorizedCartItems = Object.entries(
      carts.reduce((acc, item) => {
        const category = products.find(
          (p) => p.id === item.productId
        )?.category;
        if (!acc[category]) acc[category] = [];
        acc[category].push(item);
        return acc;
      }, {})
    )
      .map(([category, items]) => {
        return `${category}:\n${items
          .map(
            (item) =>
              `${item.quantity}x ${
                products.find((p) => p.id === item.productId)?.name
              }`
          )
          .join("\n")}`;
      })
      .join("\n\n"); // Organizza per categorie e separa con spazi

    const templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      cartItems: categorizedCartItems, // Invia i prodotti suddivisi per categorie
    };

    emailjs
      .send(
        "service_imkllkd", // Inserisci il tuo SERVICE ID
        "template_9zh4l2i", // Inserisci il tuo TEMPLATE ID
        templateParams,
        "hCoK8vMAi0Fg1v7nV" // Inserisci il tuo USER ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          //Dopo l'invio dell'email svuota il carrello
          dispatch(clearCart());
          // Dopo l'invio dell'email, resetta il form
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          });
          // Chiudi il form di checkout
          setShowCheckoutForm(false);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };

  // Funzione per gestire il form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && value.length > 10) {
      return; // Non aggiornare lo stato se supera 10 cifre
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCloseTab = () => {
    dispatch(toggleStatusTab());
  };

  const handleCheckoutClick = () => {
    setShowCheckoutForm(true);
    dispatch(toggleStatusTab()); // Chiude il carrello quando si apre il form
  };

  return (
    <>
      <div
        className={`fixed top-0 right-0 bg-gradient-to-b from-[#1b558f] to-[#05b4fc] z-30 shadow-2xl w-96 h-full grid grid-rows-[60px_1fr_60px] transform duration-500 ${
          statusTab === false ? "translate-x-full" : ""
        } ${showCheckoutForm ? "hidden" : ""}`} // Nasconde il carrello se il form è aperto
      >
        <h2 className="p-5 text-white text-2xl">Carrello</h2>
        <div className="p-5">
          {carts.map((item, key) => (
            <CartItem key={key} data={item} />
          ))}
        </div>
        <div className="grid grid-cols-2">
          <button onClick={handleCloseTab} className="bg-black text-white">
            CHIUDI
          </button>
          <button
            onClick={handleCheckoutClick}
            disabled={isCartEmpty}
            className={`text-white ${
              isCartEmpty === true ? "bg-amber-800" : "bg-amber-600"
            }`}
          >
            RICHIEDI UN PREVENTIVO
          </button>
        </div>
      </div>

      {showCheckoutForm && (
        <>
          {/* Sfocatura dello sfondo */}
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-10"></div>

          {/* Form di checkout centrato */}
          <div className="fixed inset-0 flex items-center justify-center z-20">
            <div className="checkout-form bg-white p-6 rounded shadow-lg w-96 gap-5 flex flex-col">
              <div className="flex gap-2 items-center flex-col">
                <h2 className="font-bold">Inserisci i tuoi dati</h2>
                <p className="text-red-600 text-xs">
                  Tutti i campi sono obbligatori
                </p>
              </div>

              <form onSubmit={sendEmail}>
                <input
                  type="text"
                  name="firstName"
                  placeholder="Nome"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                  className="border p-2 mb-2 w-full"
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Cognome"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                  className="border p-2 mb-2 w-full"
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="border p-2 mb-2 w-full"
                />
                <input
                  type="tel"
                  name="phone"
                  placeholder="Numero di Telefono"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                  className="border p-2 mb-2 w-full"
                />

                <h3 className="font-bold">Prodotti nel carrello</h3>

                {/* Suddivisione per categorie */}
                {Object.entries(
                  carts.reduce((acc, item) => {
                    const category = products.find(
                      (p) => p.id === item.productId
                    )?.category;
                    if (!acc[category]) acc[category] = [];
                    acc[category].push(item);
                    return acc;
                  }, {})
                ).map(([category, items], index) => (
                  <div key={index}>
                    <h4 className="font-semibold mt-2">{category}</h4>
                    <ul className="ml-4">
                      {items.map((item, i) => (
                        <li key={i}>
                          {item.quantity}x{" "}
                          {products.find((p) => p.id === item.productId)?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}

                <div className="grid grid-cols-2 gap-5 mt-4">
                  <button className="bg-blue-500 text-white p-2 rounded">
                    Richiedi un preventivo
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowCheckoutForm(false)}
                    className="bg-black text-white p-2 rounded"
                  >
                    Indietro
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CartTab;
