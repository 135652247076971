import React from "react";
import { Link } from "react-router-dom";

const ProductItem = ({ text, img, textLink }) => {
  return (
    <div className="relative rounded-lg w-[150px] md:w-[200px] h-[250px] md:h-[300px] bg-gradient-to-r from-[#1b558f] to-[#05b4fc] flex flex-col items-center group">
      {/* Image */}
      <div className="flex justify-center p-6">
        <img src={img} alt="" className="w-24 md:w-32 h-40 md:h-52" />
      </div>

      {/* Text */}
      <div className="text-center">
        <h3 className="text-xs md:text-sm font-medium text-white text-center">
          {text}
        </h3>
      </div>

      {/* Hover Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 flex items-center justify-center transition-opacity duration-300">
        <Link
          to={textLink}
          className="text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        >
          Scopri di più
        </Link>
      </div>
    </div>
  );
};

export default ProductItem;
