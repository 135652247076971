import ProductItem from "../components/productslistcomponents/productItem";
import img1 from "../assets/images/porteInterne/ProfiliZefiro.png";
import img2 from "../assets/images/porteBlindate/sikura.png";
import img3 from "../assets/images/serramentiPvc/scorrevole-alzante.jpg";
import img4 from "../assets/images/serramentiLegnoAlluminio/standard.png";
import img5 from "../assets/images/zanzariere/ambra.webp";

const ProductsList = () => {
  return (
    <div className="flex flex-col items-center w-full gap-10 md:gap-20 md:h-screen">
      <div className="flex flex-col gap- items-start mt-10 mx-10 md:mt-20 md:mx-20">
        <div className="flex flex-col gap-1">
          <h1 className="text-2xl md:text-5xl font-bold bg-gradient-to-r from-[#1b558f] to-[#05b4fc] bg-clip-text text-transparent">
            PRODOTTI
          </h1>

          <h3 className="text-xl md:text-2xl">Scopri i nostri prodotti</h3>
        </div>
        <p className="text-left md:w-1/2 md:text-xl opacity-60">
          Offriamo porte blindate, interne, infissi e zanzariere che coniugano
          stile e sicurezza. Dai modelli eleganti ai design moderni, i nostri
          prodotti soddisfano vari gusti. Certificati per la trasmittanza
          termica, garantiscono comfort e sicurezza.
        </p>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-10 mb-10">
        <ProductItem
          text={"Porte"}
          textLink={"/prodotti/porte-interne"}
          img={img1}
        />
        <ProductItem
          text={"Porte Blindate"}
          textLink={"/prodotti/porte-blindate"}
          img={img2}
        />
        <ProductItem
          text={"Serramenti PVC"}
          textLink={"/prodotti/serramenti-PVC"}
          img={img3}
        />
        <ProductItem
          text={"Serramenti legno-alluminio"}
          textLink={"/prodotti/serramenti-legno-alluminio"}
          img={img4}
        />
        <ProductItem
          text={"Zanzariere"}
          textLink={"/prodotti/zanzariere"}
          img={img5}
        />
      </div>
    </div>
  );
};

export default ProductsList;
