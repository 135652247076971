import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleStatusTabHamburger } from "../store/hamburger";
import { useLocation } from "react-router-dom";

const HamburgerMenu = () => {
  const statusTab = useSelector((store) => store.hamburger.statusTab);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleOpenHamburger = () => {
    dispatch(toggleStatusTabHamburger());
  };

  const isHome = location.pathname === "/";

  return (
    <div
      className={`fixed top-0 left-0 bg-gradient-to-b from-[#1b558f] to-[#05b4fc] z-30 shadow-2xl w-full h-full transform duration-500 ${
        statusTab === false ? "-translate-x-full" : ""
      } `}
    >
      <button
        onClick={handleOpenHamburger}
        className="text-black text-xl mb-4 flex justify-end p-5 w-full "
      >
        <p className="rounded-full bg-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </p>
      </button>

      <div className="flex flex-col gap-10 items-center mt-20 text-white font-bold text-xl">
        <Link to="/">
          <button onClick={handleOpenHamburger}>Home</button>
        </Link>
        <Link to="/productsList">
          <button onClick={handleOpenHamburger}>Prodotti</button>
        </Link>
        {isHome && (
          <>
            <a onClick={handleOpenHamburger} href="#joinUs">
              Dove Siamo
            </a>
            <a onClick={handleOpenHamburger} href="#contactUs">
              Contattaci
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default HamburgerMenu;
