// import React from "react";
// import iconQuality from "../../assets/icons/icon-quality.png";
// import iconManutention from "../../assets/icons/icon-manutention.png";
// import iconClock from "../../assets/icons/icon-clock.png";
// import iconLock from "../../assets/icons/lock.png";
// import iconSales from "../../assets/icons/icon-sales.png";
// import iconWarrenty from "../../assets/icons/icons8-protezione-selezionata-100.png";

// const WhatWeOffer = () => {
//   return (
//     <section className="py-10 h-[600px] flex flex-col items-center gap-10">
//       <div className="flex flex-col items-center justify-center gap-8 mt-20">
//         <h1 className="text-5xl font-bold">COSA OFFRIAMO</h1>
//         <p className="text-center text-xl w-1/2 opacity-60">
//           Offriamo soluzioni su misura, innovative, sicure e funzionali,
//           adattandole a ogni stile e contesto. Un mix di tecnologia innovstiva e
//           artigianato, selezionando prodotti Made in Italy di alta qualità.{" "}
//         </p>
//       </div>
//       <div className="grid grid-cols-3 gap-5">
//         <div className="flex flex-col items-center gap-5">
//           <img
//             className="w-20 h-20 p-4 bg-gradient-to-br from-[#1b558f] to-[#05b4fc] rounded-full"
//             src={iconQuality}
//           />
//           <p className="font-bold text-center w-2/3">
//             Qualità al prezzo più conveniente
//           </p>
//         </div>
//         <div className="flex flex-col items-center gap-5">
//           <img
//             className="w-20 h-20 p-4 bg-gradient-to-br from-[#1b558f] to-[#05b4fc] rounded-full"
//             src={iconManutention}
//           />
//           <p className="font-bold text-center w-2/3">
//             Manutenzione post-vendita
//           </p>
//         </div>
//         <div className="flex flex-col items-center gap-5">
//           <img
//             className="w-20 h-20 p-4 bg-gradient-to-br from-[#1b558f] to-[#05b4fc] rounded-full"
//             src={iconClock}
//           />
//           <p className="font-bold text-center w-2/3">Consegne puntuali</p>
//         </div>
//         <div className="flex flex-col items-center gap-5">
//           <img
//             className="w-20 h-20 p-4 bg-gradient-to-br from-[#1b558f] to-[#05b4fc] rounded-full"
//             src={iconLock}
//           />
//           <p className="font-bold text-center w-2/3">
//             Rispetto della privacy del cliente
//           </p>
//         </div>
//         <div className="flex flex-col items-center gap-5">
//           <img
//             className="w-20 h-20 p-4 bg-gradient-to-br from-[#1b558f] to-[#05b4fc] rounded-full"
//             src={iconSales}
//           />
//           <p className="font-bold text-center w-2/3">Promozioni ed incentivi</p>
//         </div>
//         <div className="flex flex-col items-center gap-5">
//           <img
//             className="w-20 h-20 p-4 bg-gradient-to-br from-[#1b558f] to-[#05b4fc] text-white rounded-full"
//             src={iconWarrenty}
//           />
//           <p className="font-bold text-center w-2/3">
//             Garanzia estendibile 2-10 anni
//           </p>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default WhatWeOffer;
import React from "react";
import { motion } from "framer-motion";
import iconQuality from "../../assets/icons/icon-quality.png";
import iconManutention from "../../assets/icons/icon-manutention.png";
import iconClock from "../../assets/icons/icon-clock.png";
import iconLock from "../../assets/icons/lock.png";
import iconSales from "../../assets/icons/icon-sales.png";
import iconWarrenty from "../../assets/icons/icons8-protezione-selezionata-100.png";

const WhatWeOffer = () => {
  const iconVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1 },
  };

  return (
    <section className="py-10 h-[800px] flex flex-col items-center gap-10">
      <div className="flex flex-col items-center justify-center gap-8 mt-28">
        <h1 className=" text-4xl mg:text-5xl font-bold">COSA OFFRIAMO</h1>
        <p className="text-center px-5 md:text-xl md:w-1/2 opacity-60">
          Offriamo soluzioni su misura, innovative, sicure e funzionali,
          adattandole a ogni stile e contesto. Un mix di tecnologia innovativa e
          artigianato, selezionando prodotti Made in Italy di alta qualità.
        </p>
      </div>
      <div className="grid grid-cols-3 gap-4 md:gap-5">
        <motion.div
          className="flex flex-col items-center gap-5"
          variants={iconVariants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <img
            className="w-16 h-16 md:w-20 md:h-20 p-4 bg-gradient-to-br from-[#1b558f] to-[#05b4fc] rounded-full"
            src={iconQuality}
            alt="Qualità"
          />
          <p className="text-xs md:text-basetext-xs md:text-base font-semibold md:font-bold text-center w-2/3">
            Qualità al prezzo più conveniente
          </p>
        </motion.div>

        <motion.div
          className="flex flex-col items-center gap-5"
          variants={iconVariants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <img
            className="w-16 h-16 md:w-20 md:h-20 p-4 bg-gradient-to-br from-[#1b558f] to-[#05b4fc] rounded-full"
            src={iconManutention}
            alt="Manutenzione"
          />
          <p className="text-xs md:text-base font-semibold md:font-bold text-center md:w-2/3">
            Manutenzione post-vendita
          </p>
        </motion.div>

        <motion.div
          className="flex flex-col items-center gap-5"
          variants={iconVariants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <img
            className="w-16 h-16 md:w-20 md:h-20 p-4 bg-gradient-to-br from-[#1b558f] to-[#05b4fc] rounded-full"
            src={iconClock}
            alt="Consegne puntuali"
          />
          <p className="text-xs md:text-base font-semibold md:font-bold text-center w-2/3">
            Consegne puntuali
          </p>
        </motion.div>

        <motion.div
          className="flex flex-col items-center gap-5"
          variants={iconVariants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <img
            className="w-16 h-16 md:w-20 md:h-20 p-4 bg-gradient-to-br from-[#1b558f] to-[#05b4fc] rounded-full"
            src={iconLock}
            alt="Rispetto privacy"
          />
          <p className="text-xs md:text-base font-semibold md:font-bold text-center w-2/3">
            Rispetto della privacy del cliente
          </p>
        </motion.div>

        <motion.div
          className="flex flex-col items-center gap-5"
          variants={iconVariants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <img
            className="w-16 h-16 md:w-20 md:h-20 p-4 bg-gradient-to-br from-[#1b558f] to-[#05b4fc] rounded-full"
            src={iconSales}
            alt="Promozioni"
          />
          <p className="text-xs md:text-base font-semibold md:font-bold text-center md:w-2/3">
            Promozioni ed incentivi
          </p>
        </motion.div>

        <motion.div
          className="flex flex-col items-center gap-5"
          variants={iconVariants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <img
            className="w-16 h-16 md:w-20 md:h-20 p-4 bg-gradient-to-br from-[#1b558f] to-[#05b4fc] text-white rounded-full"
            src={iconWarrenty}
            alt="Garanzia"
          />
          <p className="text-xs md:text-base font-semibold md:font-bold text-center w-2/3">
            Garanzia estendibile 2-10 anni
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default WhatWeOffer;
