import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaShoppingCart } from "react-icons/fa";
import logo from "../assets/images/logoBevilacquaserramenti.png";
import { useSelector, useDispatch } from "react-redux";
import { toggleStatusTab } from "../store/cart";
import { toggleStatusTabHamburger } from "../store/hamburger";
import { motion } from "framer-motion"; // Importa framer-motion

const Navbar = () => {
  const [totalQuantity, setTotalQuantity] = useState(0);
  const carts = useSelector((store) => store.cart.items);
  const dispatch = useDispatch();
  const location = useLocation(); // Ottieni l'url corrente

  useEffect(() => {
    let total = 0;
    carts.forEach((item) => (total += item.quantity));
    setTotalQuantity(total);
  }, [carts]);

  const handleOpenTab = () => {
    dispatch(toggleStatusTab());
  };
  const handleOpenHamburger = () => {
    dispatch(toggleStatusTabHamburger());
  };

  // Varianti di animazione per Framer Motion
  const leftDiv = {
    hidden: { opacity: 0, y: -50, x: -600 }, // Inizialmente nascosto sopra la pagina
    visible: { opacity: 1, y: 0, x: 0, transition: { duration: 1 } }, // Animazione di discesa
  };
  const rightDiv = {
    hidden: { opacity: 0, y: -50, x: +600 }, // Inizialmente nascosto sopra la pagina
    visible: { opacity: 1, y: 0, x: 0, transition: { duration: 1 } }, // Animazione di discesa
  };

  return (
    <motion.header
      className="flex justify-between items-center text-white mx-2 md:mx-0"
      initial="hidden"
      animate="visible"
    >
      <motion.div
        variants={leftDiv}
        className="md:hidden cursor-pointer"
        onClick={handleOpenHamburger}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </motion.div>
      <motion.div variants={leftDiv}>
        <Link to="/" className="flex gap-4 items-center">
          <motion.img
            src={logo}
            alt="logo"
            className="rounded-full w-12 h-12 object-cover"
            whileHover={{ scale: 1.1 }}
          />
          <motion.h1
            className="hover:text-black hidden md:block"
            whileHover={{ scale: 1.1 }}
          >
            Home
          </motion.h1>
        </Link>
      </motion.div>

      <motion.div className="flex gap-5 items-center" variants={rightDiv}>
        <motion.div className="md:flex gap-5 hidden ">
          <Link to="/productsList">
            <motion.p
              className="cursor-pointer hover:text-black"
              whileHover={{ scale: 1.1 }}
            >
              Prodotti
            </motion.p>
          </Link>

          {/* Mostra questi link solo se sei nella homepage */}
          {location.pathname === "/" && (
            <>
              <a href="#joinUs">
                <motion.p
                  className="cursor-pointer hover:text-black"
                  whileHover={{ scale: 1.1 }}
                >
                  Dove Siamo
                </motion.p>
              </a>

              <a href="#contactUs">
                <motion.p
                  className="cursor-pointer hover:text-black"
                  whileHover={{ scale: 1.1 }}
                >
                  Contattaci
                </motion.p>
              </a>
            </>
          )}
        </motion.div>

        <motion.div
          onClick={handleOpenTab}
          className="w-10 h-10 bg-black hover:bg-white hover:text-black rounded-full flex justify-center items-center relative cursor-pointer"
          whileHover={{ scale: 1.1 }} // Effetto di ingrandimento sull'icona del carrello
        >
          <FaShoppingCart size={25} />
          <motion.span
            className="absolute top-2/3 right-1/2 bg-red-500 text-white text-sm w-5 h-5 rounded-full flex justify-center items-center"
            whileHover={{ scale: 1.1 }}
          >
            {totalQuantity}
          </motion.span>
        </motion.div>
      </motion.div>
    </motion.header>
  );
};

export default Navbar;
