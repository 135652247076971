import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const ProductsSection = () => {
  const imgVariants = {
    hidden: { opacity: 0, x: 400 },
    visible: { opacity: 1, x: 0, transition: { duration: 1.5 } },
  };

  const textVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1, transition: { duration: 1.5 } },
  };

  return (
    <section className="bg-white h-[800px] flex justify-between">
      <motion.div
        variants={textVariants}
        initial="hidden"
        whileInView="visible"
        className="flex flex-col gap-14 items-center md:ml-20 md:items-start mt-40"
      >
        <div className="flex flex-col gap-5 items-center md:items-start">
          <h1 className="font-bold text-3xl md:text-5xl">I NOSTRI PRODOTTI</h1>
          <p className="opacity-60 text-center md:text-left text-xl w-2/3">
            I nostri prodotti <span className="font-bold">MADE IN ITALY </span>
            si contraddistinguono per:
            <span className="font-bold"> Qualità, Efficienza, Durabilità </span>
            ed <span className="font-bold">Ecosostenibilità</span>.
          </p>
        </div>
        <Link to="/productsList">
          <button className="bg-gradient-to-r from-[#1b558f] to-[#05b4fc] rounded-xl px-10 py-5 text-white font-bold transform transition-transform duration-300 hover:scale-105">
            Catalogo
          </button>
        </Link>
        <img
          src="https://lh3.googleusercontent.com/p/AF1QipPoWd_naRK58biJ2_5diqJ85N0YgAqfeGSkxPFs=s680-w680-h510"
          className="w-[50%] h-[250px] mb-20 md:hidden"
          alt=""
        />
      </motion.div>

      <motion.div
        variants={imgVariants}
        initial="hidden"
        whileInView="visible"
        className="mt-10 lg:grid grid-cols-2 gap-0 mr-20 hidden"
      >
        <img
          src="https://lh3.googleusercontent.com/p/AF1QipPoWd_naRK58biJ2_5diqJ85N0YgAqfeGSkxPFs=s680-w680-h510"
          alt=""
          className="w-[300px] h-[350px]"
        />
        <img
          src="https://lh3.googleusercontent.com/p/AF1QipO9MLvV-u1l3ix1xqfSF1ko_s1amPKdOCkpAfWM=s680-w680-h510"
          className="w-[300px] h-[350px]  translate-y-[100%]"
          alt=""
        />
      </motion.div>
    </section>
  );
};

export default ProductsSection;
