import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: localStorage.getItem("carts")
    ? JSON.parse(localStorage.getItem("carts"))
    : [],
  statusTab: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const { productId, quantity } = action.payload;
      const indexProductId = state.items.findIndex(
        (item) => item.productId === productId
      );
      if (indexProductId >= 0) {
        state.items[indexProductId].quantity += quantity;
      } else {
        state.items.push({ productId, quantity });
      }
      localStorage.setItem("carts", JSON.stringify(state.items));
    },

    changeQuantity(state, action) {
      const { productId, quantity } = action.payload;
      const indexProductId = state.items.findIndex(
        (item) => item.productId === productId
      );

      if (indexProductId >= 0) {
        if (quantity > 0) {
          // Aggiorna la quantità se il prodotto esiste e la quantità è valida
          state.items[indexProductId].quantity = quantity;
        } else {
          // Rimuovi il prodotto se la quantità è zero o meno
          state.items = state.items.filter(
            (item) => item.productId !== productId
          );
        }
        localStorage.setItem("carts", JSON.stringify(state.items));
      } else {
        console.warn(`Product with ID ${productId} not found in cart.`);
      }
    },

    clearCart(state) {
      state.items = []; // Svuota il carrello
      localStorage.setItem("carts", JSON.stringify(state.items));
    },

    toggleStatusTab(state) {
      if (state.statusTab === false) {
        state.statusTab = true;
      } else {
        state.statusTab = false;
      }
    },
  },
});
export const { addToCart, changeQuantity, clearCart, toggleStatusTab } =
  cartSlice.actions;
export default cartSlice.reducer;
